import React, { useEffect } from "react"
import { Layout } from "../components"
import RubyVyvojarKaJobDetail from "./ruby-vyvojar-ka-job-detail"
import { Helmet } from "react-helmet"
import { Banner, BannerStyle } from "@igloonet-web/shared-ui"

export default function LinuxAdmin() {
  useEffect(() => {
    document.body.classList.add("modal-open")

    return function cleanup() {
      document.body.classList.remove("modal-open")
    }
  }, [])
  return (
    <Layout>
      <Helmet>
        <title>Pojď nám prosím spravit Redmine! | igloonet</title>
        <meta
          name="description"
          content="Hledáme externího či part-time parťáka, který by nám pomohl s údržbou a rozvojem Redmine a dalších interních systémů v Ruby (on Rails)."
        />
        <meta property="og:title" content="Pojď nám prosím spravit Redmine!" />
      </Helmet>

      <Banner
        style={BannerStyle.Jobs}
        orangeHeading="Každý strávíme třetinu života v práci."
        blackHeading="Vybírej chytře, s kým ji prožiješ"
        text="Chceš pracovat v kolektivu, kde bude tvé slovo slyšet?
        Staň se tučňákem a pracuj v igloo. #nejsmezledu"
      />

      <RubyVyvojarKaJobDetail />
    </Layout>
  )
}
